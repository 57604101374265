import React, { useState } from 'react';

function Logo() {
  return (
    <a href="/">
      <img src="/images/logo.png" />
    </a>
  );
}

function MenuLink(a) {
  return (
    <li><a href={a.link}>{a.title}</a></li>
  );
}

export default function Header() {
  return (
    <header>
      {/*<Logo/>*/}
      {/*<MenuLink link="/" title="Home"/>
      <MenuLink link="test" title="Test"/>
      <MenuLink link="scrapbook" title="Scrapbook"/>*/}
    </header>
  );
}