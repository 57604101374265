import React, { useState } from 'react';

export default function Footer() {
  return (
    <footer>
      <div className="ct-header-socials " data-id="socials" id="socials">
        <div className="ct-social-box" data-color="custom" data-icon-size="custom" data-icons-type="simple">
          <a href="http://instagram.com/hecksplorer" data-network="instagram" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
            <span className="ct-icon-container">
              <svg width="20" height="20" viewBox="0 0 20 20" aria-hidden="true">
                <circle cx="10" cy="10" r="3.3"></circle>
                <path d="M14.2,0H5.8C2.6,0,0,2.6,0,5.8v8.3C0,17.4,2.6,20,5.8,20h8.3c3.2,0,5.8-2.6,5.8-5.8V5.8C20,2.6,17.4,0,14.2,0zM10,15c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S12.8,15,10,15z M15.8,5C15.4,5,15,4.6,15,4.2s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8S16.3,5,15.8,5z"></path>
              </svg>
            </span>
          </a>
            <a href="http://facebook.com/hecksplorer" data-network="facebook" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
            <span className="ct-icon-container">
              <svg width="20px" height="20px" viewBox="0 0 20 20" aria-hidden="true">
                <path d="M20,10.1c0-5.5-4.5-10-10-10S0,4.5,0,10.1c0,5,3.7,9.1,8.4,9.9v-7H5.9v-2.9h2.5V7.9C8.4,5.4,9.9,4,12.2,4c1.1,0,2.2,0.2,2.2,0.2v2.5h-1.3c-1.2,0-1.6,0.8-1.6,1.6v1.9h2.8L13.9,13h-2.3v7C16.3,19.2,20,15.1,20,10.1z"></path>
              </svg>
            </span>
          </a>
            <a href="http://pinterest.com/hecksplorer" data-network="pinterest" aria-label="Pinterest" target="_blank" rel="noopener noreferrer">
            <span className="ct-icon-container">
              <svg width="20px" height="20px" viewBox="0 0 20 20" aria-hidden="true">
                <path d="M10,0C4.5,0,0,4.5,0,10c0,4.1,2.5,7.6,6,9.2c0-0.7,0-1.5,0.2-2.3c0.2-0.8,1.3-5.4,1.3-5.4s-0.3-0.6-0.3-1.6c0-1.5,0.9-2.6,1.9-2.6c0.9,0,1.3,0.7,1.3,1.5c0,0.9-0.6,2.3-0.9,3.5c-0.3,1.1,0.5,1.9,1.6,1.9c1.9,0,3.2-2.4,3.2-5.3c0-2.2-1.5-3.8-4.2-3.8c-3,0-4.9,2.3-4.9,4.8c0,0.9,0.3,1.5,0.7,2C6,12,6.1,12.1,6,12.4c0,0.2-0.2,0.6-0.2,0.8c-0.1,0.3-0.3,0.3-0.5,0.3c-1.4-0.6-2-2.1-2-3.8c0-2.8,2.4-6.2,7.1-6.2c3.8,0,6.3,2.8,6.3,5.7c0,3.9-2.2,6.9-5.4,6.9c-1.1,0-2.1-0.6-2.4-1.2c0,0-0.6,2.3-0.7,2.7c-0.2,0.8-0.6,1.5-1,2.1C8.1,19.9,9,20,10,20c5.5,0,10-4.5,10-10C20,4.5,15.5,0,10,0z"></path>
              </svg>
            </span>
          </a>
            <a href="http://tiktok.com/@hecksplorer" data-network="tiktok" aria-label="TikTok" target="_blank" rel="noopener noreferrer">
            <span className="ct-icon-container">
              <svg width="20px" height="20px" viewBox="0 0 20 20" aria-hidden="true">
                <path d="M18.2 4.5c-2.3-.2-4.1-1.9-4.4-4.2V0h-3.4v13.8c0 1.4-1.2 2.6-2.8 2.6-1.4 0-2.6-1.1-2.6-2.6s1.1-2.6 2.6-2.6h.2l.5.1V7.5h-.7c-3.4 0-6.2 2.8-6.2 6.2S4.2 20 7.7 20s6.2-2.8 6.2-6.2v-7c1.1 1.1 2.4 1.6 3.9 1.6h.8V4.6l-.4-.1z"></path>
              </svg>
            </span>
          </a>
        </div>
      </div>
      {/*<p>
        &copy; Hecksplorer - {(new Date().getFullYear())}
      </p>*/}
    </footer>
  );
}