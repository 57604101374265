import React, { Suspense, lazy } from 'react';
import Header from './Header';
import Footer from './Footer';

export default function Page({type}) {
  const Component = lazy(() => import(`../pages/${type.charAt(0).toUpperCase() + type.slice(1)}`));

  return (
    <>
      <Header />
      <div className="page">
        <Suspense fallback={<div>Loading...</div>}>
          <Component />
        </Suspense>
      </div>
      <Footer />
    </>
  );
}
