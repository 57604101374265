// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../httpdocs/images/background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --heck-blue: #22507F;
  --heck-green: #1A8C44;
  --heck-pink: #FFFFFF;
  --heck-purple: #CFDDCC;
}

.App {
  margin: 0;
  padding: 0;
  text-align: center;
}

body {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
}
#main {
    min-height: 80vh;
    text-align: center;
    position: relative;
    padding-bottom: 25%;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
h1 {
    color: #74b66e;
    font-size: 150%;
    margin: 0 auto;
    margin-bottom: -5vh;
    padding-top: 1vh;
}
#banner {
    width: 100%;
    margin-top: 60px;
}
#john {
    bottom: 0;
    position: absolute;
    left: 50%;
    right: 50%;
    max-width: 90%;
    transform: translate(-45%);
}
#socials {
    border-top: 5px solid #74b66e;
    padding: 10px 0 5px 0;
    text-align: center;
}
#socials a {
    display: inline-block;
    padding: 2px 10px;
    color: pink;
}
@media screen and (max-width: 1024px) {
    #socials a {
        width: 15%;
    }
    #socials svg {
        width: 50%;
        height: auto;
    }
    #john {
        width: 90%;
    }
    h1 {
        font-size: 250%;
    }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,oBAAoB;EACpB,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,kBAAkB;AACpB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,kCAAkC;AACtC;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,mDAAoD;AACxD;AACA;IACI,cAAc;IACd,eAAe;IACf,cAAc;IACd,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,SAAS;IACT,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,cAAc;IACd,0BAA0B;AAC9B;AACA;IACI,6BAA6B;IAC7B,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,WAAW;AACf;AACA;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;QACV,YAAY;IAChB;IACA;QACI,UAAU;IACd;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[":root {\n  --heck-blue: #22507F;\n  --heck-green: #1A8C44;\n  --heck-pink: #FFFFFF;\n  --heck-purple: #CFDDCC;\n}\n\n.App {\n  margin: 0;\n  padding: 0;\n  text-align: center;\n}\n\nbody {\n    margin: 0;\n    padding: 0;\n    font-weight: 500;\n    font-family: 'DM Sans', sans-serif;\n}\n#main {\n    min-height: 80vh;\n    text-align: center;\n    position: relative;\n    padding-bottom: 25%;\n    background: url('../httpdocs/images/background.jpg');\n}\nh1 {\n    color: #74b66e;\n    font-size: 150%;\n    margin: 0 auto;\n    margin-bottom: -5vh;\n    padding-top: 1vh;\n}\n#banner {\n    width: 100%;\n    margin-top: 60px;\n}\n#john {\n    bottom: 0;\n    position: absolute;\n    left: 50%;\n    right: 50%;\n    max-width: 90%;\n    transform: translate(-45%);\n}\n#socials {\n    border-top: 5px solid #74b66e;\n    padding: 10px 0 5px 0;\n    text-align: center;\n}\n#socials a {\n    display: inline-block;\n    padding: 2px 10px;\n    color: pink;\n}\n@media screen and (max-width: 1024px) {\n    #socials a {\n        width: 15%;\n    }\n    #socials svg {\n        width: 50%;\n        height: auto;\n    }\n    #john {\n        width: 90%;\n    }\n    h1 {\n        font-size: 250%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
